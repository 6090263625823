import { graphql, useStaticQuery } from 'gatsby'


const useAllPestTypesQuery = () => useStaticQuery(graphql`
    query AllPestTypesQuery {
        pestTypes: allWpPestType(sort: { fields: name }) {
            nodes {
            name
            slug
            pests {
                nodes {
                    title
                    slug
                    featuredImage {
                        node {
                            localFile {
                                    childImageSharp {
                                        fixed(height: 90) {
                                            ...GatsbyImageSharpFixed_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`);

export default useAllPestTypesQuery;