import React, { useState } from 'react'
import { graphql, navigate } from 'gatsby'
import tw from 'twin.macro'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import PestCard from '../components/pests/PestCard'

import SEO from '../components/Seo'
import Layout from '../components/Layout'
import A from '../components/A'
import PestSelectModal from '../components/pests/PestSelectModal'
import { ButtonLink, Button } from '../components/Button'
import useAllPestTypesQuery from '../hooks/useAllPestTypes';


const OtherPestCard = ({ title, slug, onClick, highlighted }) => {
  return (
    <Button
      onClick={() => onClick(slug)}
      pill
      outline={!highlighted}
      secondary={!highlighted}
      tw="m-2 text-gray-800"
    >
      {title}
    </Button>
  )
}

const PestTemplate = ({ data, preview }) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedPestType, setSelectedPestType] = useState({})

  const previewData = (Array.isArray(preview?.revisions) && preview?.revisions?.length)
    ? preview.revisions[0]
    : preview;

  const pageData = preview 
    ? previewData
    : data;

  const {
    pest: {
      title,
      pestDetails: { genus, introduction, habits, prevention },
      pestTypeDetails: { nodes: pestTypeDetailsNodes },
    },
  } = pageData;

  const {pestTypes: { nodes: pestTypeNodes }} = useAllPestTypesQuery();

  const ptDetails =
    pestTypeDetailsNodes?.length > 0 ? pestTypeDetailsNodes[0] : {}

  const allPestTypes = pestTypeNodes.map(x => {
    return {
      title: x.name,
      slug: x.slug,
      pests: x.pests.nodes.map(pest => {
        return {
          fixedImagePath:
            pest.featuredImage.node.localFile.childImageSharp.fixed,
          title: pest.title,
          slug: pest.slug,
        }
      }),
    }
  })

  const relatedPests = allPestTypes
    .find(x => x.slug === ptDetails.slug)
    .pests.filter(pest => pest.title !== title)

  const aboutView = (
    <>
      {(preview === false) ? (
        <Img
          fixed={data.pest.featuredImage.node.localFile.childImageSharp.fixed}
          tw="-ml-16"
        />
      ) : (
        <img 
          src={pageData.pest.featuredImage.node.sourceUrl} 
          alt={ptDetails.name}
          css={[
            tw`-ml-16`,
            `max-width: 220px;`,

          ]}
        />
      )}
      <div css={['max-width:400px;', tw`px-4 lg:pl-32`]}>
        <div tw="font-bold text-2xl font-sans text-gray-800">
          About {ptDetails.name}
        </div>
        <div
          tw="prose max-w-full text-lg mt-2 font-sans leading-7 text-gray-800"
          dangerouslySetInnerHTML={{ __html: ptDetails.description }}
        ></div>
      </div>
    </>
  )

  const familyView = genus ? (
    <div tw="text-3xl font-bold">{`(Family: ${genus})`}</div>
  ) : null

  const exploreView =
    relatedPests?.length > 0 ? (
      <>
        <div tw="mt-10 text-xl font-bold">{`Explore Other ${ptDetails.name}`}</div>
        <div tw="grid grid-cols-2 col-gap-8 row-gap-8">
          {relatedPests.map(pest => (
            <PestCard key={pest.id} title={pest.title} slug={pest.slug} imagePath={pest.fixedImagePath} horizontal />
          ))}
        </div>
      </>
    ) : null

  const preventionView = prevention ? (
    <>
      <div tw="mt-10 text-3xl font-bold">Prevention</div>
      <div
        tw="prose max-w-full text-lg mt-3 font-sans text-gray-800"
        dangerouslySetInnerHTML={{ __html: prevention }}
      ></div>
    </>
  ) : null

  const habitsView = habits ? (
    <>
      <div tw="mt-10 text-3xl font-bold">Habits</div>
      <div
        tw="prose max-w-full text-lg mt-3 font-sans text-gray-800"
        dangerouslySetInnerHTML={{ __html: habits }}
      ></div>
    </>
  ) : null

  const onOtherPestTypeClick = slug => {
    const selectedPestType = allPestTypes.find(x => x.slug === slug)
    if (selectedPestType.pests.length === 1) {
      navigate(`/learn/${selectedPestType.pests[0].slug}`)
    } else {
      setSelectedPestType(selectedPestType)
      setShowModal(true)
    }
  }

  return (
    <>
      <SEO />
      <Layout>
        
        <PestSelectModal
          show={showModal}
          onClose={() => setShowModal(false)}
          pestType={selectedPestType}
        />
        <div tw="md:(grid grid-cols-10)">
          <div tw="col-span-3">
            {aboutView}
          </div>
          <div tw="px-4 lg:px-10 mt-10 col-span-7">
            <A tw="mt-4 text-2xl" href="/learn">
              Learn »
            </A>
            <div
              tw="prose max-w-full text-5xl font-bold"
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>
            {familyView}
            <div
              tw="prose max-w-full text-lg mt-3 font-sans text-gray-800"
              dangerouslySetInnerHTML={{ __html: introduction }}
            ></div>
            {habitsView}
            {preventionView}
            {exploreView}
            <div tw="mt-10 text-3xl font-bold">Explore Other Pests</div>
            <div tw="flex flex-wrap mt-3">
              {allPestTypes.map(pestType => (
                <OtherPestCard
                  key={pestType.slug}
                  title={pestType.title}
                  slug={pestType.slug}
                  highlighted={ptDetails.slug === pestType.slug}
                  onClick={onOtherPestTypeClick}
                />
              ))}
            </div>
          </div>
        </div>
        <div tw="flex justify-center items-center flex-col">
          <div tw="lg:text-5xl md:text-3xl sm:text-2xl text-2xl font-bold text-center mt-16">
            You don't have to live with{' '}
            <span tw="text-blue-600">{ptDetails.name}</span>
          </div>
          <div css={['max-width:50%;', tw`text-center text-gray-700 mx-auto`]}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </div>
          <ButtonLink tw="mt-8" large to="/quote">
            Get a Quote <FontAwesomeIcon icon={faChevronRight} tw="ml-8" />
          </ButtonLink>
        </div>
      </Layout>
    </>
  )
}


export const query = graphql`
  query GetSinglePest($slug: String) {
    pest: wpPest(slug: { eq: $slug }) {
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fixed(height: 300) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
      pestDetails: pests {
        prevention
        fieldGroupName
        genus
        habits
        introduction
      }
      pestTypeDetails: pestTypes {
        nodes {
          description
          slug
          name
        }
      }
    }
  }
`

export default PestTemplate
